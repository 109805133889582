import React from "react"
import { Link } from "gatsby"
import styled from "@emotion/styled"
import Twitter from "./twitterIcon"
import Instagram from "./instagramIcon"
import LinkedIn from "./linkedInIcon"
import Logo from "./logoGradient"
import ArrowRight from "./arrowRight"
import Location from "./location"

const StyledFooter = styled.footer`
  padding: 0 0 5% 0;
  width: 100%;
  position: relative;
`
const StyledLogo = styled(Link)`
  width: 150px;
  display: inline-block;
`

const RoundedWrapper = styled.div`
  height: 50px;
  position: relative;
  overflow: hidden;
`

const Rounded = styled.div`
  width: 150vw;
  height: calc(175px + 5%);
  border-radius: 42% 58% 70% 30% / 45% 45% 55% 55%;
  position: absolute;
  left: 50%;
  top: 0;
  transform: translate(-50%, 0);
  z-index: 1;
  background-color: #000;
`

const Footer = () => {
  return (
    <>
      <RoundedWrapper className="bg-white">
        <Rounded className="bg-black"></Rounded>
      </RoundedWrapper>
      <StyledFooter className="bg-black navbar-dark">
        <div className="container-fluid position-relative">
          <div className="row text-center">
            <div className="col-12 offset-md-2 col-md-10 col-lg-8">
              <a
                href="mailto:amsterdam@chunkagency.com"
                className="d-inline-flex align-items-center white link--line-under-span"
              >
                <span className="h5 font-weight-light">Drop us a line</span>
                <span className="h6 circle bg-gradient">
                  <ArrowRight />
                </span>
              </a>
            </div>
          </div>
          <div className="row d-flex justify-content-between mt-5 mt-md-7">
            <div className="col-12 col-md-2">
              <ul className="navbar-nav list-unstyled text-uppercase">
                <li className="nav-item">
                  <Link
                    className="nav-link white link--line-under"
                    activeClassName="active"
                    to="/"
                  >
                    Home
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className="nav-link white link--line-under"
                    activeClassName="active"
                    to="/our-work"
                  >
                    Our work
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className="nav-link white link--line-under"
                    activeClassName="active"
                    to="/our-approach/digital-design"
                  >
                    Our approach
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className="nav-link white link--line-under"
                    activeClassName="active"
                    to="/about-us"
                  >
                    About us
                  </Link>
                </li>
                {/* <li className="nav-item">
                  <a
                    className="nav-link white link--line-under"
                    href="https://chunk.homerun.co/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Careers
                  </a>
                </li> */}
                <li className="nav-item">
                  <Link
                    className="nav-link white link--line-under"
                    activeClassName="active"
                    to="/contact"
                  >
                    Contact
                  </Link>
                </li>
              </ul>
            </div>
            <div className="col-12 col-md-10 col-lg-8">
              <div className="row d-flex justify-content-between">
                <div className="col-12 col-sm-6 text-md-right">
                  <h5 className="h6 white text-uppercase mt-3 mt-md-0 mb-1 mb-md-3">
                    Amsterdam
                  </h5>
                  <p className="small font-weight-light text-uppercase">
                    <a
                      className="nav-link nav-link--location white font-weight-light p-0 link--line-under"
                      href="https://maps.app.goo.gl/ooCPTjgDk6oepbpK9"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <span className="icon--location">
                        <Location />
                      </span>
                      <span>Wibaustraat 129</span>
                    </a>
                    <br />
                    1091 GL Amsterdam
                    <br />
                    <a
                      className="nav-link white p-0 font-weight-light link--line-under"
                      href="mailto:amsterdam@chunkagency.com"
                    >
                      amsterdam@chunkagency.com
                    </a>
                  </p>
                </div>
                <div className="col-12 col-sm-6 text-left">
                  <h5 className="h6 white text-uppercase mt-3 mt-md-0 mb-1 mb-md-3">
                    London
                  </h5>
                  <p className="small font-weight-light text-uppercase">
                    <a
                      className="nav-link nav-link--location nav-link--location-inverse white font-weight-light p-0 link--line-under"
                      href="https://maps.app.goo.gl/H4siQUuYnHXgdmFq5"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <span className="icon--location">
                        <Location />
                      </span>
                      <span>Borough Road 79-81</span>
                    </a>
                    <br />
                    1UV London
                    <br />
                    <a
                      className="nav-link white font-weight-light p-0 link--line-under"
                      href="mailto:london@chunkagency.com"
                    >
                      london@chunkagency.com
                    </a>
                  </p>
                </div>
              </div>
              <div className="row">
                <div className="col-12 text-md-center">
                  <StyledLogo
                    to="/"
                    className="brand__logo svg-gradient white mt-6 mt-md-8 mb-md-0"
                  >
                    <Logo></Logo>
                  </StyledLogo>
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-2 mt-3 mt-lg-0">
              <ul className="navbar-nav navbar-nav--social list-unstyled flex-row flex-lg-column justify-content-lg-end">
                <li className="nav-item">
                  <a
                    className="h6 svg-gradient gradient-border circle"
                    href="https://www.linkedin.com/company/chunk/"
                    target="_blank"
                    rel="noreferrer"
                    aria-label="LinkedIn"
                  >
                    <LinkedIn />
                  </a>
                </li>

                <li className="nav-item">
                  <a
                    className="h6 svg-gradient gradient-border circle"
                    href="https://instagram.com/chunkcreativeagency"
                    target="_blank"
                    rel="noreferrer"
                    aria-label="Instagram"
                  >
                    <Instagram />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </StyledFooter>
    </>
  )
}

Footer.propTypes = {}

Footer.defaultProps = {}

export default Footer
