/* eslint-disable react/display-name */
import React from "react"
import { Link } from "gatsby"
import { Keyframes, config } from "react-spring/renderprops"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import {
  faTwitter,
  faInstagram,
  faLinkedinIn,
} from "@fortawesome/free-brands-svg-icons"
import delay from "delay"

import classNames from "classnames/bind"

const MenuItems = Keyframes.Trail({
  open: async next => {
    await delay(50)
    await next({
      opacity: 1,
      transform: "translate3d(0px, 0px, 0px)",
    })
  },
  closed: async next => {
    await next({
      opacity: 0,
      transform: "translate3d(0px, -20px, 0px)",
    })
  },
})

const SocialItems = Keyframes.Trail({
  open: async next => {
    await delay(550)
    await next({
      opacity: 1,
      transform: "translate3d(0px, 0px, 0px)",
    })
  },
  closed: async next => {
    await next({
      opacity: 0,
      transform: "translate3d(20px, 0px, 0px)",
    })
  },
})

const Menu = ({ isDesktop, isOpen, onClose }) => {
  const menuClass = classNames({
    "navbar-nav--header navbar-nav flex-grow-1 d-flex p-4": true,
  })

  const items = [
    {
      link: "/",
      text: "Home",
      target: false,
    },
    {
      link: "/our-work",
      text: "Our work",
      target: false,
    },
    {
      link: "/our-plans",
      text: "Our plans",
      target: false,
    },

    {
      link: "/our-approach/digital-design",
      text: "Our approach",
      target: false,
    },
    {
      link: "/about-us",
      text: "About us",
      target: false,
    },
    // {
    //   link: "https://chunk.homerun.co",
    //   text: "We're hiring",
    //   target: true,
    // },
    
    {
      link: "/contact",
      text: "Contact",
      target: false,
    },
    {
      link: "social",
      text: "Social",
      target: false,
    },
  ]

  const socials = [
    {
      label: "LinkedIn",
      link: "https://www.linkedin.com/company/chunk",
      icon: <FontAwesomeIcon icon={faLinkedinIn} />,
    },
    {
      label: "Instagram",
      link: "https://instagram.com/chunkcreativeagency",
      icon: <FontAwesomeIcon icon={faInstagram} />,
    },
  ]

  return (
    <>
      <ul className={menuClass}>
        <MenuItems
          keys={item => item.link}
          items={items}
          state={isOpen ? "open" : "closed"}
          reverse={!isOpen}
          xconfig={config.fast}
          config={{
            mass: 1,
            tension: 240,
            friction: 15,
            clamp: true,
          }}
        >
          {item => ({ ...trailprops }) => {
            const target = item.target
              ? { target: "_blank", rel: "noopener" }
              : {}
            return (
              <li className="nav-item" style={trailprops}>
                {item.link !== "social" &&
                  (!item.target ? (
                    <Link
                      className="h3 font-weight-light nav-link link--line-under black-line"
                      onClick={event => {
                        onClose()
                      }}
                      to={item.link}
                      activeClassName="active"
                    >
                      {item.text}
                    </Link>
                  ) : (
                    <a
                      className="h3 font-weight-light nav-link link--line-under black-line"
                      target={target}
                      onClick={event => {
                        onClose()
                      }}
                      href={item.link}
                    >
                      {item.text}
                    </a>
                  ))}
                {item.link === "social" && (
                  <ul className="navbar-nav list-unstyled flex-row justify-content-start mt-5 mx-n2">
                    <SocialItems
                      keys={social => social.link}
                      items={socials}
                      state={isOpen ? "open" : "closed"}
                      reverse={!isOpen}
                      config={{
                        mass: 1,
                        tension: 240,
                        friction: 15,
                        clamp: true,
                      }}
                    >
                      {socialItem => ({ ...socialTrailprops }) => (
                        <li className="nav-item mr-1" style={socialTrailprops}>
                          <a
                            className="h5 black black-border circle"
                            onClick={event => {
                              onClose()
                            }}
                            href={socialItem.link}
                            aria-label={socialItem.label}
                          >
                            {socialItem.icon}
                          </a>
                        </li>
                      )}
                    </SocialItems>
                  </ul>
                )}
              </li>
            )
          }}
        </MenuItems>
      </ul>
    </>
  )
}

export default Menu
